/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

@font-face {
	font-family: 'Pangea SemiBold';
	src: url(/_next/static/media/PangeaAfrikanTrial-SemiBold.8bf05c53.woff);
}

@font-face {
	font-family: 'Pangea Medium';
	src: url(/_next/static/media/PangeaAfrikanTrial-Medium.851184e6.woff);
}

@font-face {
	font-family: 'Pangea MediumItalic';
	src: url(/_next/static/media/PangeaAfrikanTrial-MediumItalic.d606d89f.woff);
}

@font-face {
	font-family: 'Pangea Regular';
	src: url(/_next/static/media/PangeaAfrikanTrial-Regular.385440c4.woff);
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	max-width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	font-family: 'Pangea Regular';
	font-size: 0.875rem;
	color: #262626;
}

body::-webkit-scrollbar {
	display: none;
}

.ant-drawer .ant-drawer-header {
	border-bottom: none !important;
	padding: 1.5rem !important;
}

.ant-drawer-body {
	padding-top: 0px !important;
}

.ant-select-selector {
	height: 3.125rem !important;
	background-color: #f2f2f2 !important;
	font-family: 'Pangea SemiBold' !important;
}

.ant-select-selector .ant-select-selection-item {
	line-height: 3rem !important;
	font-size: 1rem !important;
}

.responsive-scroll-menu::-webkit-scrollbar {
	display: none;
}

.text-dark-45 {
	color: #8c8c8c;
}

.background-dark-45-hover:hover {
	background-color: #d9d9d9;
}

.text-semi-bold {
	font-family: 'Pangea SemiBold';
}

.text-medium {
	font-family: 'Pangea Medium';
}

.text-medium-italic {
	font-family: 'Pangea MediumItalic';
}

.dark-85-hover:hover path {
	fill: #262626;
}

.dark-85-path path {
	fill: #262626;
}

.primary-color-svg path {
	fill: var(--primary-color);
}

.ant-segmented {
	background-color: #f2f2f2 !important;
	padding: 0.25rem !important;
}

.ant-segmented-item-selected .ant-segmented-item-label {
	color: var(--primary-color) !important;
}

.ant-segmented .ant-segmented-item-label {
	height: 3.125rem !important;
	line-height: 3.125rem !important;
	font-family: 'Pangea SemiBold' !important;
}

#yousign-iframe-container iframe {
	width: 100%;
	height: 100%;
}

