@font-face {
	font-family: 'Pangea SemiBold';
	src: url('../fonts/PangeaAfrikanTrial-SemiBold.woff');
}

@font-face {
	font-family: 'Pangea Medium';
	src: url('../fonts/PangeaAfrikanTrial-Medium.woff');
}

@font-face {
	font-family: 'Pangea MediumItalic';
	src: url('../fonts/PangeaAfrikanTrial-MediumItalic.woff');
}

@font-face {
	font-family: 'Pangea Regular';
	src: url('../fonts/PangeaAfrikanTrial-Regular.woff');
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	max-width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	font-family: 'Pangea Regular';
	font-size: 0.875rem;
	color: #262626;
}

body::-webkit-scrollbar {
	display: none;
}

.ant-drawer .ant-drawer-header {
	border-bottom: none !important;
	padding: 1.5rem !important;
}

.ant-drawer-body {
	padding-top: 0px !important;
}

.ant-select-selector {
	height: 3.125rem !important;
	background-color: #f2f2f2 !important;
	font-family: 'Pangea SemiBold' !important;
}

.ant-select-selector .ant-select-selection-item {
	line-height: 3rem !important;
	font-size: 1rem !important;
}

.responsive-scroll-menu::-webkit-scrollbar {
	display: none;
}

.text-dark-45 {
	color: #8c8c8c;
}

.background-dark-45-hover:hover {
	background-color: #d9d9d9;
}

.text-semi-bold {
	font-family: 'Pangea SemiBold';
}

.text-medium {
	font-family: 'Pangea Medium';
}

.text-medium-italic {
	font-family: 'Pangea MediumItalic';
}

.dark-85-hover:hover path {
	fill: #262626;
}

.dark-85-path path {
	fill: #262626;
}

.primary-color-svg path {
	fill: var(--primary-color);
}

.ant-segmented {
	background-color: #f2f2f2 !important;
	padding: 0.25rem !important;
}

.ant-segmented-item-selected .ant-segmented-item-label {
	color: var(--primary-color) !important;
}

.ant-segmented .ant-segmented-item-label {
	height: 3.125rem !important;
	line-height: 3.125rem !important;
	font-family: 'Pangea SemiBold' !important;
}

#yousign-iframe-container iframe {
	width: 100%;
	height: 100%;
}
